@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');

body {
  font-family: 'Lora', serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: url(./background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
*{
  cursor: url("cursor.png"), auto!important;
}
a {
  text-decoration: none;
}
a:hover, .MuiSvgIcon-root:hover, .arrow:hover, .socials:hover{
  cursor: url("curhov.png"), auto!important;
}

.mainlogo {
  width: 80px !important;
  align-self: inherit !important;
  z-index: 110;
  background: transparent;
  position: relative;
  border-radius: 100px;
}

.mainlogowrap {
  background: transparent !important;
  z-index: 110;
  backdrop-filter: none;
}

nav {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: auto;
  padding-top: 24px;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
}

.flexnav {
  display: flex;
  align-items: center;
}

nav .link,
nav a {
  position: relative;
  color: #000;
  font-style: normal;
  font-size: 18px;
  margin: 8px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 8px;
  text-decoration: none;
  backdrop-filter: blur(12px);
  height: 24px;
  background-color: rgba(255, 255, 255, 0.4);
  transition: all 500ms ease 0s;
  display: flex;
  justify-content: center;
  align-items: center;
}


nav .link::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  height: 0px;
  width: 0px;
  border-radius: 100px;
  background-color: var(--accent-color);
  transition: all 500ms ease 0s;
}
nav .link:hover::before {
  cursor: pointer;
  height: 100%;
  color: white !important;
  width: 100%;
  border-radius: 8px;
}

nav .link:hover {
  color: white !important;
}


.linkactive {
  cursor: pointer;
  background-color: var(--accent-color) !important;
  color: white !important;
}

.homelink {
  font-family: Druk;
  font-size: 14px !important;
  padding: 11px 16px !important;
}

.hide-800 {
  display: flex;
}

.menu-button {
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 30px;
  height: 30px;
  top: 2rem;
  right: 20px;
  z-index: 110;
  transition: 0.3s all ease-in-out;

}

.menu-button .line-top {
  position: relative;
  height: 2px;
  background: #000;
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.menu-button .line-bottom {
  position: relative;
  height: 2px;
  background: #000;
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.close-button .line-top {
  transform: rotate(45deg) translateY(2px);
}

.close-button .line-bottom {
  transform: rotate(-45deg) translateY(-1px);
}

.close-button {
  gap: 0px;
  transition: 0.3s all ease-in-out;
  z-index: 110;
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.8px);
  -webkit-backdrop-filter: blur(12.8px);
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.65);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.1px);
  -webkit-backdrop-filter: blur(10.1px);
  z-index: -1;
  transition: 0.3s all ease-in-out;
  opacity: 0;
}

.mobile-nav-inner {
  padding-top: 100px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

}

.divider {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.075);
}

.mobile-nav a {
  color: white;
  font-size: 20px;
  font-weight: 800;
}

.open-menu {
  opacity: 1;
  z-index: 100;
}

.socials {
  flex-direction: row;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 16px;
  padding: 8px 16px;
  height: 24px;
  box-sizing: content-box;
  border-radius: 8px;
  text-transform: uppercase;
  backdrop-filter: blur(12px);
  background-color: rgba(255, 255, 255, 0.4);
}

.socials a {
  display: flex;
  color: rgb(0, 0, 0);
  transition: all 200ms ease 0s;
  margin: 0px 8px;
  cursor: pointer;
  padding: 0;
  backdrop-filter: none;
  background: transparent;
}

.socials a:hover {
  color: var(--accent-color);
}

.social-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-mobile img {
  width: 20px;
}

nav {
  padding-top: 14px;
  width: 99%;
}

.section {
  height: 100vh;
  width: 1380px;
  max-width: 1380px;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.section .text {
  max-width: 40%;
}

.section .imgwrap {
  width: 40%;
  max-width: 450px;
  border-radius: 10px;
  padding: 15px;
  background: #301d15;
  box-shadow: 0px 0px 50px 0px #33292e8f;
}
.section img {
  width: 100%;
  border-radius: 10px;
}
#f1 img{
  padding: 5px;
  background: #301d15;
  border-radius: 10px;
}

.text h1 {
  font-size: 70px;
}

.white h1,
.white p {
  color: white;
}
.white{
  padding: 20px;
  background:#33292eab;
  padding-right: 50px;
  border-radius: 10px;
}

.text p {
  font-size: 18px;
  font-weight: 500;
}

.stand{
  height: 300px;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  margin-top: -70px;
}
.stand img{
  height: 100%;
}
#roadmap h1{
  padding: 8px 16px;
  background: #33292eab;
  max-width: 400px;
  margin-inline: auto;
  border-radius: 20px;
}
@media(max-width: 800px){
  .stand{
    width: 100%;
    height: auto;
    margin-top: -30px;
  }
  .stand img{
    height: auto;
    width: 100%;
  }
}

#roadmap h1 {
  text-align: center;
  font-size: 70px;
  color: white;
  -webkit-text-stroke: 2px black;
}

.roadmap-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1100px;
  margin-inline: auto;
}
#roadmap{
  padding: 150px 0;
}

.book {
  position: relative;
  width: 423px;
  height: 610px;
  transition: transform 0.5s;
}
@media(max-width: 1000px){
  .book{
    width: 170px;
  height: 245px;
  }
  #roadmap{
    padding: 0px 0;
  }
}
.paper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  perspective: 1500px;

}

.front,
.back {
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: left;
  transition: transform 0.5s;
  user-select: none;
}

.front {
  z-index: 1;
  backface-visibility: hidden;

}

.back {
  z-index: 0;
}

.front-content,
.back-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.front-content img, .back-content img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#f1 img{
  width: 106%;
  height: 106%;
}
#b7 img{
  width: 100%;
  height: 100%;
  object-fit:cover;
}
.b7{
  background: #594335;
}


.arrow {
  user-select: none;
}

.back-content {
  transform: rotateY(180deg)
}

/* Paper flip effect */
.flipped .front,
.flipped .back {
  transform: rotateY(-180deg);
}

/* Paper stack order */
#p1 {
  z-index: 7;
}

#p2 {
  z-index: 6;
}

#p3 {
  z-index: 5;
}

#p4 {
  z-index: 4;
}

#p5 {
  z-index: 3;
}

#p6 {
  z-index: 2;
}

#p7 {
  z-index: 1;
}

.faq {
  padding: 150px 0;
  padding-top: 300px;
  width: 1380px;
  max-width: 1380px;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  position: relative;
}

.faq img {
  height: 500px;
  border-radius: 10px;
  border: 1px solid #33292e8f;
  width: calc(40% - 40px);
  object-fit: cover;
  box-shadow: 0px 0px 50px 0px #33292e8f;

}

.faq {
  align-items: flex-start;
}

.faq img {
  height: 700px;
}

.faqq p {
  margin: 10px 0;
  color: white;
}

.text-part .title h2 {
  font-size: 70px;
  line-height: 70px;
  font-weight: 800;
  margin: 0;
  margin-left: -6px;
  color: white;

}

.text-part .title h3 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 800;
  margin: 0;
  color: white;

}

.title {
  margin-bottom: 40px;
}

.first {
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
}

.MuiAccordion-root::before {
  display: none !important;
}

.MuiAccordion-root {
  background: rgba(0, 0, 0, 0.2) !important;
  width: 100%;

}

.MuiTypography-root {
  color: white !important;
  font-family: 'Lora', serif !important;
  font-weight: 600 !important;
}

.MuiTypography-root span {
  color: white !important;
}

.MuiSvgIcon-root {
  color: white !important;
}

.Mui-expanded .MuiAccordionSummary-root .MuiTypography-root {
  color: white !important;
}

.Mui-expanded .MuiSvgIcon-root {
  color: white !important;
}

.team {
  padding: 150px 0;
  width: 1380px;
  max-width: 1380px;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.flexteam {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.team-member img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  border: 1px solid #33292e8f;
  box-shadow: 0px 0px 50px 0px #0000008f;
  padding: 12px;
  background: #301d15;
}

.team h1 {
  font-size: 70px;
  color: white;
  -webkit-text-stroke: 2px black;
}

.team-member h4 {
  color: white;
  margin: 0px;
}

.team-member h3 {
  margin-bottom: 4px;
}

footer {
  height: 300px;
  position: relative;
}

.footer {
  height: 60%;
  width: 1380px;
  max-width: 1380px;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 60px;
}

footer hr {
  width: 1380px;
  max-width: 1380px;
  margin-inline: auto;
  border: 1px solid white;
  opacity: 0.5;
  margin-top: 0;
}

.flexstart {
  justify-content: flex-start;
  width: 1380px;
  max-width: 1380px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  color: white;
}

@media(max-width: 1480px) {

  .footer,
  footer hr,
  .flexstart {
    width: calc(100% - 200px);
  }
}

.footer-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.footer-member h4 {
  font-weight: 600;
  font-size: 14px;
  color: white;
}

footer {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.596));
}

.discord svg {
  color: #5069c5;
}

.twitter svg {
  color: #4eabe8;
}

.instagram svg {
  color: #e80d72;
}

.footer-member a {
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  border: 1px solid white;
  padding: 8px 16px;
  border-radius: 30px;
}

.footer-member a:hover {
  box-shadow: 0px 0px 30px 0px #33292e5b;
}

.arrow {
  width: 80px;
  cursor: pointer;

}

.team-member h3 {
  color: white;
}
.abtus{
  margin-top: 100px;
}

.loadscr {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #594335;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  top: 0;
  color: white;
}

.loadscr img {
  width: min(10vw, 200px);
  border-radius: 50px;
}
@keyframes moveout {
  0% {
    transform: translateY(0px);
    opacity: 1;
    z-index: 100;
  }

  50% {
    transform: translateY(0px);
    opacity: 1;
    z-index: 100;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
    display: none;
  }
}
@media(max-width: 1480px) {

  .section,
  .faq,
  .team {
    width: calc(100% - 200px);
  }

  .team {
    padding: 150px 0px;
  }
}

@media(max-width: 1000px) {
  .menu-button {
    display: flex;
    position: fixed;
  }

  .section,
  .faq {
    width: 90%;
    padding: 50px 0px;
    flex-direction: column;
  }

  .faq {
    margin-top: 50px;
  }

  .faq img,
  .section img {
    width: 80%;
    margin-inline: auto;
    height: auto;
  }

  .hide-800 {
    display: none;
  }

  .roadmap-container {
    width: 95%;
  }

  .section .text {
    max-width: 95%;
    width: 95%;
  }

  .section {
    justify-content: center;
  }

  .abtus {
    height: auto;
    gap: 10px;
    flex-direction: column-reverse;
  }

  .abtus h1 {
    margin-bottom: 0;
  }

  .abtus p {
    margin-top: 0;
  }

  .text h1 {
    font-size: 40px;
  }

  .text p {
    font-size: 16px;
  }

  .abtus {
    justify-content: space-between;
  }

  .pages {
    height: 260px;
    width: 360px;
  }

  .page img {
    width: 100%;
    height: 100%;
  }

  #roadmap h1 {
    font-size: 40px;
  }

  .text-part .title h2 {
    font-size: 40px;
    line-height: 40px;
  }

  .text-part .title h3 {
    font-size: 14px;
    line-height: 14px;
  }

  .team,
  .footer {
    padding: 40px 0;
    width: 95%;
  }

  footer hr,
  .flexstart {
    width: 95%;
  }

  .team h1 {
    font-size: 40px;
  }

  .flexteam {
    flex-direction: column;
    gap: 20px;
  }

  .arrow {
    width: 40px;
  }

  footer {
    height: auto;
    background: linear-gradient(transparent 2%, #2b2119 6%);
  }

  .footer {
    flex-direction: column;
    height: auto;
  }

  .flexstart p {
    font-size: 12px;
  }

  .team-member img {
    width: 250px;
    height: 250px;
    border-radius: 10px;
  }

  .mainlogo {
    width: 60px !important;
  }
}

.mint {
  background: black;
  color: white;
}

nav a:hover {
  box-shadow: 0px 0px 30px 0px #33292e5b;
}

* {
  scroll-behavior: smooth;
}